<template>
  <div class="Newspaper">
    <div class="mainWidth">
      <!-- 我说 -->
      <div class="flow" v-if="bksj_sk.length">
        <el-row type="flex" justify="center">
          <el-col>
            <div class="box_h">
              <div class="mains">
                <!-- 头部 -->
                <div class="head">
                  <div class="headl">
                    <img src="../../assets/image/line.png" alt="" />
                    <div class="round"></div>
                  </div>
                  <div class="cent" @click="bkship(47)">
                    <!-- 点击专题跳转 -->
                    <img src="../../assets/image/woshuo.png" />
                    <span>我说</span>
                  </div>
                  <div class="headl">
                    <div class="round"></div>
                    <img src="../../assets/image/line.png" alt="" />
                  </div>
                </div>
                <!-- 头部下面的卡片信息 -->
                <div class="ws_bot">
                  <!-- 单张卡片 -->
                  <el-row :gutter="$store.state.isEquipment == 'pc' ? 40 : 0">
                    <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-for="(a, b) in bksj_sk" :key="b">
                      <div class="borbox">
                        <router-link target="_blank"
                          :to="{ path: '/xq', query: { qing: a.id, mian: 'navbk' } }">
                          <el-row :gutter="$store.state.isEquipment == 'pc' ? 21 : 0">
                            <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
                              <div class="smil_h">
                                <img v-lazy="a.pub_cover" alt="" />
                              </div>
                            </el-col>
                            <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
                              <div class="smil_r">
                                <div class="smil_topz">
                                  {{ a.SYS_TOPIC }}
                                </div>
                                <div class="smil_bot">
                                  <span class="text">
                                    {{ $AwayTell(a.DESCRIPTION, 0, $store.state.isSmallPc ? 50 : 110) }}
                                    <span class="particulars">[详细]</span>
                                  </span>
                                </div>
                              </div>
                            </el-col>
                          </el-row>
                        </router-link>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 融合报道 -->
      <div class="rong_he" v-if="bksj_rh.length">
        <el-row type="flex" justify="center">
          <el-col>
            <!-- 头部 -->
            <div class="head">
              <div class="headl">
                <img src="../../assets/image/line.png" alt="" />
                <div class="round"></div>
              </div>
              <div class="cent" style="margin: 0 1.1875rem" @click="jump">
                <img src="../../assets/image/ping.png" alt="" style="width: 2.5625rem" />
                <span>融合报道</span>
              </div>
              <div class="headl">
                <div class="round"></div>
                <img src="../../assets/image/line.png" alt="" />
              </div>
            </div>
            <!-- 融合报道3个卡片 -->
            <div class="kade_th">
              <div class="cad_k">
                <!-- 轮播图 -->
                <Swiper :options="swiperOption">
                  <SwiperSlide v-for="(a, b) in bksj_rh" :key="b">
                    <div align="center" class="crad">

                      <div class="Visu">
                        <el-row>
                          <el-col :span="8" v-for="item in 1" :key="item.index">
                            <router-link target="_blank"
                              :to="{ path: '/xq', query: { qing: a.id, mian: 'navbks' } }">

                              <!-- <img v-lazy="a.pub_cover" alt="" /> -->
                              <img :src="a.pub_cover" v-if="[0,1,2].includes(b) || b == (bksj_rh.length - 1)|| b == (bksj_rh.length - 2)|| b == (bksj_rh.length - 3)">
                              <img v-lazy="a.pub_cover" alt="" v-else>

                              <div class="card_content">
                                <p class="title">
                                  {{ a.SYS_TOPIC }}
                                </p>
                                <p class="text" v-html="a.DESCRIPTION">

                                </p>
                              </div>
                            </router-link>

                          </el-col>
                        </el-row>
                      </div>

                    </div>

                  </SwiperSlide>

                  <div class="swiper-pagination" slot="pagination"></div>

                </Swiper>
              </div>
              <!-- 两个箭头 -->
              <div class="arrowhead arrows">
                <img src="../../assets/image/jan_left.png" alt="" class="swiper-button-prev" />
                <img src="../../assets/image/jan_left.png" alt="" class="swiper-button-next"
                  style="transform: rotateZ(180deg);" />
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 底部视频 -->
      <div class="ship_s">
        <video_bot :sendText="sendText"></video_bot>
      </div>
    </div>
  </div>
</template>

<script>
import video_bot from "@/components/Shiping.vue";
import api from "@/api/index.js";

export default {
  components: {
    video_bot,
  },
  props: {},
  data() {
    return {
      sendText: "",
      bksj_sk: '',
      bksj_shuo: '',
      bksj_rh: '',
      swiperOption: {
        slidesPerGroup: this.$store.state.isEquipment == 'phone' ? 1 : 3,
        initialSlide: 0,
        loop: true,
        paginationClickable: true,
        EffectCards: true,
        slidesPerView: this.$store.state.isEquipment == 'phone' ? 1 : 3,
        spaceBetween: 20,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  created() {
    api
      .post("spc/cms/publish/queryList.do", {
        s: 2,
        c: 49,
      })
      .then((res) => {
        this.sendText = res.result.slice(0, 3);
      });
  },
  methods: {
    jump() {
      let routerJump = this.$router.resolve({
        path: "/bksjList",
        query: { id: 48 },
      });
      window.open(routerJump.href, '_blank')
    },
    bkship(falg) {
      let routerJump = this.$router.resolve({
        path: "/woshuo",
      });
      window.open(routerJump.href, '_blank')
    },
  },
  mounted() {
    api.post('spc/cms/publish/queryList.do', {
      s: 2,
      c: 47
    }).then((res) => {
      this.bksj_sk = res.result.slice(0, 6)
    })
    api.post('spc/cms/publish/queryList.do', {
      s: 2,
      c: 48
    }).then((res) => {
      this.bksj_rh = res.result
    })
  },
};
</script>
<style lang="scss" scoped>
.Newspaper {
  min-height: 100vh;
}

.kong {
  width: 100%;
  height: 3.8125rem;
}

.flow {
  background: url(../../assets/image/linebei.png) no-repeat;
  background-size: 100% 100%;
  margin: 1rem;
}

.ship_s {
  margin: 0 auto;
  // padding-bottom: 1.875rem;
}

.mains {
  .ws_bot {
    .borbox {
      padding-top: 1.5625rem;
      padding-bottom: 1.25rem;
      border-bottom: 1px dashed #becedf;

      .smil_h {
        margin-bottom: 0.5rem;

        img {
          width: 100%;
          height:198px;
          vertical-align: top;
        }
      }

      .smil_r {
        width: 100%;
        height: 100%;

        .smil_topz {
          margin-bottom: 0.5rem;
          font-size: 1.25rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #212121;
          line-height: 2rem;
          display: -webkit-box;
          overflow: hidden;
          white-space: normal !important;
          text-overflow: ellipsis;
          word-wrap: break-word;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .smil_topz:hover {
          color: #2c769a;
        }

        .smil_bot {
          .text {
            font-size: 1rem;
            font-weight: 400;
            color: #707070;
            line-height: 1.5rem;
            overflow: hidden;
          }

          .particulars {
            color: #2c769a;
            font-size: 1rem;
            cursor: pointer;
          }
        }
      }
    }
  }
  @media screen and(max-width:1440px) {
    .ws_bot .borbox .smil_h img{
      height:136px;
    }
  }
}

.head {
  width: 100%;
  height: 2.625rem;
  display: flex;
  justify-content: center;
  padding-top: 1rem;

  .headl {
    display: flex;
    align-items: center;

    img {
      float: left;
      width: 96%;
      height: .0625rem;
    }

    .round {
      width: .625rem;
      height: .625rem;
      border: 1px solid #d6d6d6;
      border-radius: 50%;
      float: left;
    }
  }

  .cent {
    display: flex;
    line-height: 2.625rem;
    margin: 0 1.4375rem;
    cursor: pointer;
    white-space: nowrap;

    img {
      width: 1.8125rem;
      height: 2.625rem;
      vertical-align: bottom;
    }

    span {
      font-size: 1.875rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #212121;
      margin-left: .3125rem;
      vertical-align: bottom;
    }
  }
}

// 融合报道
.rong_he {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 1rem;

  .kade_th {
    margin-top: 1rem;

    .arrowhead {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1rem;
      padding-bottom: 1.5rem;
      --swiper-navigation-size: 0;

      img {
        width: 2.5rem;
        height: 2.5rem;
        position: static;
      }

      img:last-child {
        margin-left: 1.5rem;
        position: static;
      }
    }

    .cad_k {
      .cad_one {
        width: 27.5rem;
        height: 22.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        img {
          width: 100%;
          height: 15.75rem;
          display: block;
        }

        .xi_sky {
          width: 26.625rem;
          margin: 0 auto;
          font-size: 1rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #707070;
          line-height: 1.875rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
      }
    }
  }
}

.Visu {
  background: #f5f5f5;

  .el-row {
    .el-col {
      width: 100%;
    }
  }

  img {
    width: 100%;
    height:340px;
    // height: 12rem;
    // height: 17.625rem;
    cursor: pointer;
  }

  .card_content {
    padding: 0.5rem 1.3rem;
    cursor: pointer;
    text-align: start;

    .title {
      font-size: 1.25rem;
      font-weight: 400;
      color: #212121;
      line-height: 2.25rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      height: 4.5rem;
    }

    .title:hover {
      color: #2c769a;
    }

    .text {
      margin-top: 0.5rem;
      font-size: 1rem;
      font-weight: 400;
      color: #666666;
      line-height: 1.875rem;
      height: 5.625rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      text-align: justify;
    }
  }
  @media screen and(max-width:1440px) {
    img{
      height:240px;
    }
  }
}
</style>
